.rec{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh; 
    margin: auto;
    background-color: black;
}

.rec video{
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  /* border-radius: 30px; */
  /* border: 2px solid white; */
}

.rec_btn{
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    /* margin-top: 15px; */
    height: 10vh;
    /* height: 10%; */
}



.rec h2{
  margin-top: 10px;
  text-align: center;
  color: white;
  font-size: 25px;
  font-family: sans-serif;
}

@media (min-width: 768px) {
    .toggle-btn {
      display: none; 
    }
  }
  

  @media (max-width: 767px) {
    .toggle-btn {
      display: block; 
    }
  }

.start-stop{
  background-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    border:1px solid rgb(0, 0, 0);
    /* padding: 5px 10px; */
    /* border-radius: 7px; */
    width: 50px;
    height: 50px;
    border-radius: 50%;
    position: absolute;
    bottom: 15%;
    
}

.toggle-btn{
  /* background-color:transparent; */
  background-color: antiquewhite;
  border:none;
  border-radius: 50%;
  position: absolute;
    top: 5%;
    right: 5%;
    font-size: 20px;
    /* width: 50px;
    height: 50px; */
    width: 35px;
    height: 35px;
    
}

.video-mirrored{
  width: 100%;
  /* height: 90vh; */
  height: 90%;
  object-fit: cover;
  margin: auto;
  overflow: hidden;
}

@media (min-width: 768px) {
  .video-mirrored {
      /* height: 90vh; Full viewport height on mobile devices */
      height: 80%;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .video-mirrored {
    height: 90%; /* 80% on large screens */
  }
}

.start-stop-btn{
  /* background-color: rgb(255, 255, 255); */
  color: black;
  font-weight: 700;
  font-size: 17px;
  border-radius: 5px;
  padding: 5px 10px;
}

.recording-btn{
  /* background-color: rgb(255, 255, 255); */
  color: rgb(0, 0, 0);
  font-size: 17px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  /* padding: 5px 10px; */
  /* position: absolute;
  bottom: 2%; */
  position: relative;
  bottom: 2px;
  border: 1px solid gray;
}

.active-btn{
  border: 2px solid red;
  color: red;
}

.download-btn{
  background-color:transparent;
  color: rgb(0, 0, 0);
  font-size: 17px;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  position: absolute;
  top: 10%;
  right: 5%;
  border: none;
  /* padding: 5px 10px; */
  
}

.upload-btn{
  width: 80px;
  height: 45px;
  border-radius: 28px;
  font-size: 18px;
  font-weight: 700;
  border: 1px solid gray;
}

.video-link {
  margin-top: 20px;
  padding: 10px;
  text-align: center;         
}

.video-url-input{
  width: 75%;
  border-radius: 5px;
  padding: 5px;
}

.copy-btn{
  padding: 5px 10px;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border: none;
  /* background-color: white; */
  color: black;
  background-color:transparent;
  position: absolute;
  top: 15%;
  right: 5%;
}


.alert-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.alert-box {
  background: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
}

.back-btn{
  width: 80px;
  height: 45px;
  border-radius: 28px;
  font-size: 18px;
  font-weight: 700;
  border: 1px solid gray;
}

.recaptcha-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
}

/* video-msg */
.video-msg{
  background-color: rgb(0, 0, 0);
  padding: 10px;
  position: fixed;        
    top: 50%;               
    left: 50%;             
    transform: translate(-50%, -50%); 
    width: 400px;      
    box-shadow: 0 0 15px rgba(255, 255, 255, 0.873); 
    border-radius: 5px;
}

.video-msg p{
  color: rgb(255, 255, 255);
  font-size: 25px;
  text-align: center;
}

@media (max-width:'768px'){
  .video-msg{
    width: 300px;
    padding: 7px;
  }

  .video-msg p{
    font-size: 18px;
    text-align: center;
  }
}