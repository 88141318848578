.footer-main{
    /* background-color: rgb(21, 21, 21); */
    background-color:rgb(49, 48, 48);
    padding-top: 50px ;
    padding: 40px 0 40px 0;
    width: 80%;
}



.footer-left h1{
    font-size: 25px;
    color: white;
    text-align: center;
}

@media (max-width:678px){
.footer-left{
    text-align: center;
}

    .footer-left h1{
        font-size: 17px;
    }

.social-icons{
    justify-content: center;
}
}

.footer-btn{
    background-color:#ffd000;
    padding: 5px 20px;
    color: black;
    border-radius: 5px;
    font-weight: 700;
    margin-left: 30px;
    transition: all 0.1s ease-in-out;
    border: 1px solid black;
}

.footer-btn:hover{
    border: 2px solid rgb(0, 0, 0);
    transform: scale(1.09);
}

.social-icons{
    margin-top: 30px;
    display: flex;
    flex-direction: row;
   margin-left: 30px;
}

.icons{
    width: 25px;
    height: 25px;
    /* background-color:#ffd000; */
    color: white;
    border-radius: 50%;
    padding: 5px;
    margin: 10px;
    transition: transform 0.3s ease-in-out ;
}

.icons:hover{
    transform: scale(1.2);
    /* background-color: white; */
    background: linear-gradient(to right, #fff420 ,#ff8000);
    /* color: orange; */
    color: white;
}

.footer-right h1{
    color: white;
    font-size: 25px;
    /* float: right; */
}

@media (max-width:678px){
    .footer-right h1{
        font-size: 17px;
        margin-top: 40px;
    }
}

.contact p{
    /* color: orange; */
    color: #ffd000;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 10px;
}

.contact span{
    color: white;
}

