.modal-header{
    color: white;
}

.form-label{
    color: white;
}


.modal-content{
    height: 500px !important;
}