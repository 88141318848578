.moment-main{
    width: 90%;
    margin: auto;
    
}

.moment-heading{
    margin: 50px 0 50px 0;
    
}

.moment-heading h1{
    font-weight: 700;
    text-align: center;
    /* color: white; */
    color: black;
}

.moment-container{
    /* background-color:#00218f; */
    background-color: #ffd000;
    position: relative;
    /* background-image: url('../images/decorate1.png'); */
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    z-index: -1;
    /* z-index: 0; */
}

.moment-msg h3{
    font-size: 17px;
    font-weight: 700;
    margin-bottom: 30px;
    /* color: white; */
    color: black;
}

.moment-msg p{
    /* color: white; */
    color: black;
    font-size: 16px;
    text-align: justify;
}

.moment-msg span{
    background-color: white;
    padding: 10px;
    border-radius: 10px;
    /* border-right: 4px solid orange;
    border-bottom: 4px solid orange; */
    border-right: 4px solid black;
    border-bottom: 4px solid black;
}

.moment-col{
    margin-bottom: 50px;
}

.decorating-item1 img{
    width: 100px;
    height: 100px;
    position: relative;
    top: -50%;
    left: -8%;
}

.work-hidden {
    visibility: hidden;
    opacity: 0;
    transform: translateY(20px); /* For sliding effect */
    transition: opacity 0.5s ease, transform 0.5s ease;
}

.work-visible {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
}