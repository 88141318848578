body{
  width: 100%;
  height: 100vh;
}

.record, .upload {
    width: 100%; 
    margin-bottom: 20px; 
  }

  .vid{
    display: flex;
    flex-direction: column;
    background-color: black;
  }

  @media(min-width:768px){
    .vid{
      background-color: white;
    }
  }
  
  .App{
    /* background-color: #ffd32b; */
    margin: 0;
    padding: 0;
  }