
.main-logo img{
width: 215px;


/* height: 200px; */
}

@media (max-width:768px){
  .main-logo img{
    width: 230px;
    margin-top: 70px;
  }
}

.main-logo{
  margin-top: -50px;
  height: 180px;
}


.intro-section{
  background-color: #78fff7;
    /* background-color: #ffd32b; */
     /* background: linear-gradient(to right, #fd2aea ,#ffef00); */
     
     /* background: linear-gradient(to right, #fd2a88 ,#ffef00); */
}

.intro-container {
   /* width: 90%; */
   padding: 50px 0 50px 0;
}

.intro-image img {
    width: 100%; 
    max-width: 300px; 
    border: 2px solid black;
    border-radius: 10px;
    transition: all 0.5s ease-in-out;
}

.intro-image img:hover {
    animation: wiggle 0.5s ease-in-out;
}

.intro-image video{
    /* width: 100%; */
    width: 95%;
    border-radius: 10px;
}

@keyframes wiggle {
    0%, 100% {
      transform: rotate(0deg);
    }
    25% {
      transform: rotate(5deg);
    }
    75% {
      transform: rotate(-5deg);
    }
  }

.main-intro {
    padding: 25px 50px;
    margin-bottom: 50px;
    text-align: center;  
}

.main-intro h1 {
    font-size: 22px;  
    font-weight: 700;
}

.main-intro p {
    font-size: 17px;  
    margin-top: 35px;
    margin-bottom: 35px;
}

/* .intro-btn {
    color: #fff;
    background-color: black;
    border: 1px solid black;
    border-radius: 5px;
    box-shadow: 0px 0px 3px rgb(75, 73, 73);
    margin: auto;
    padding: 10px 40px;
    display: block; 
    width: fit-content; 
} */














/* .sticky-btn {
    color: #fff;
    background-color: black;
    border: 1px solid rgb(255, 255, 255);
    border-radius: 5px;
    box-shadow: 0px 0px 3px rgb(75, 73, 73);
    font-size: 16px;
    position: fixed;
    bottom: 20px; 
    right: 20px; 
    padding: 10px 10px;
    display: block;
    animation: wiggle 1s ease-in-out infinite;  
    z-index: 1000;
    cursor: pointer;
}

@keyframes bounce {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
    100% {
      transform: translateY(0);
    }
  }
  .sticky-btn {
    animation: bounce 1s ease-in-out infinite;
  }  */



/* MOBILE VIEW*/
@media(max-width:600px){
  .main-logo{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  

    .main-intro h1 {
        font-size: 40px;  /* Larger font size for larger screens */
        text-align: center;
    }

    .main-intro{
        padding: 20px 30px;

    }

    .main-intro p {
     
        text-align: justify;
    }

    .intro-image{
        display: flex;
    justify-content: center;

    }
}


/* TABLET AND ABOVE */
@media (min-width: 601px ) {
   

    .intro-image img {
        position: relative;
        left: 20%;  /* Center the image */
        top: 50px;
    }

    .main-intro h1 {
        font-size: 35px;  /* Larger font size for larger screens */
        text-align: center;
    }

    .main-intro p {
        font-size: 20px;  /* Adjust font size for readability */
        text-align: justify;
    }
}







@keyframes fadeIn{
    from { opacity: 0; }
  to { opacity: 1; }
}

.fade-in-on-load{
    animation: fadeIn 3s ease-out forwards;
}

@keyframes bounce{
    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
      }
      40% {
        transform: translateY(-30px);
      }
      60% {
        transform: translateY(-15px);
      }
}

.bounce-on-load {
    animation: bounce 2s ease-out 1; /* Change 'infinite' to '1' if you want it to bounce only once */
  }