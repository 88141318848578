.pricing-main {
  background-color: rgb(241, 241, 241);
}

.pricing-container {
  padding: 50px 0;
}

.pricing-heading h1 {
  margin-bottom: 50px;
  text-align: center;
  font-weight: 700;
}

.pricing-plans {
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
}

.plan-structure {
  background-color: black;
  color: white;

  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.5);
  text-align: center;
  padding: 15px;
  width: 300px;
  border-radius: 10px;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.plan-structure:hover {
  transform: translateY(-15px);
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.5);
  background-color: white;
  color: black;
  transition: 0.2s ease-in;
}

.plan-structure h2 {
  font-size: 25px;
  font-weight: 700;
  margin-bottom: 20px;
}

.plan-structure h4 {
  font-size: 50px;
  display: flex;
  flex-direction: column;
}

.plan-structure span {
  font-size: 15px;
  color: gray;
}

.plan-structure p {
  font-size: 16px;
}

.premium:hover {
  background-color: white;
  color: black;
  transition: 0.3s ease-in;
}

.pricing-btn {
  background-color: #ffd000;
  border: 1px solid black;
  border-radius: 5px;
  padding: 5px 20px;
  font-weight: 700;
  transition: all 0.3s ease-in-out;
}

.pricing-btn:hover {
  transform: scale(1.1);
  border: 2px solid black;
}

.basic-btn:hover,
.premium-btn:hover {
  transform: scale(1.05);
  cursor: pointer;
}

.premium-btn {
  background-color: #ffd000;
  color: black;
}

/* Works.css */
.work-hidden {
  visibility: hidden;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.work-visible {
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
}
