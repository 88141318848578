.custom-cursor {
  position: fixed;
  pointer-events: none;
  z-index: 1000;
  width: 32px;
  height: 32px;
  background-color: transparent;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  transition: all 100ms ease-out;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: white;
  text-align: center;
  user-select: none;
}

.custom-cursor.click {
  background-color: rgba(0, 0, 0, 0.586);
  width: 55px;
  height: 55px;
  font-size: 14px;
}
