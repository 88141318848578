.work-main{
    /* z-index: -1; */
    /* z-index: 0; */
    background-color: rgb(241 241 241);
    /* background-color: white; */
   width: 100%;
}

/* .work-container{
    width: 90%;
    margin: auto;
} */
    

.work-title h1{
    text-align: center;
    margin: 50px 0;
    font-size: 40px;
    font-weight: 700;
}


.works-step h2{
 font-size: 35px;
 font-weight: 800;
 line-height: 68px;
 -webkit-text-fill-color: white;
 -webkit-text-stroke-width: 1px;
 -webkit-text-stroke-color: black;
 text-align: start;
}

@keyframes pulse {
  0%, 100% { transform: scale(1); }
  50% { transform: scale(1.1); }
}

.pulse {
  animation: pulse 2s infinite ease-in-out;
}
 
/* 
@keyframes bounceIn {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-30px);
    }
    60% {
      transform: translateY(-15px);
    }
    80% {
      transform: translateY(-4px);
    }
  }
  
  .bounce-in {
    animation: bounceIn 1s ease-out infinite;
  }
   



   @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  .spin {
    animation: spin 2s linear infinite;
  }


  .combo {
    animation: bounceIn 1s ease-out infinite, spin 2s linear infinite;
  } */




.works-step h3{
    font-size: 20px;
    margin-bottom: 20px;
    color: rgb(255, 255, 255);
    font-weight: 700;
    /* -webkit-text-fill-color: rgb(0, 0, 0);
    -webkit-text-stroke-color: rgb(255, 255, 255);
    -webkit-text-stroke-width: 1px; */
}

.works-step p{
    color: rgb(255, 255, 255);
    font-weight: 700;
    font-size: 16px;
    text-align: justify;
    margin-top: 45px;
    /* -webkit-text-fill-color: rgb(255, 255, 255); */
    /* -webkit-text-stroke-color: rgb(215 215 215); */
    /* -webkit-text-stroke-width: 1px; */
}

.work-btn{
    color: rgb(0, 0, 0);
    /* background-color: #ffd32b; */
    background-color:#ffd000;
    border: 1px solid rgb(0, 0, 0);
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgb(75, 73, 73);
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    font-weight: 600;
    padding: 10px 40px;
    margin-bottom: 75px;
    margin-top: 30px;
    transition: all 0.1s ease-in-out;
}

.work-btn:hover{
  border: 2px solid black;
  transform: scale(1.2);
}

.work-col{
    /* box-shadow: 0px 0px 6px darkgrey; */
    box-shadow: 0px 0px 10px #5b5959;
    padding: 10px;
    border-radius: 10px;
    background-size: cover;
    aspect-ratio: 3/2;
}

@media (max-width:768px){
    .work-col{
        aspect-ratio: 1;
    }
}



.fade-in {
    animation: fadeIn 1s ease-out forwards;
  }
  
  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
  
  .slide-up {
    animation: slideUp 1s ease-out forwards;
    
  }
  
  @keyframes slideUp {
    from { transform: translateY(60px); opacity: 0;}
    to { transform: translateY(0); opacity: 1; }
  }
  
  /* Works.css */

  
.work-hidden {
    visibility: hidden;
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.5s ease, transform 0.5s ease;
}

.work-visible {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
}

.works-step{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative; /* Ensure text is above the overlay */
    z-index: 1;
    padding: 0px 20px;
    margin-top: 10px;
}

.work-row{
    display: flex;
    justify-content: space-around;
    row-gap: 40px;
}

.video-container {
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0px 0px 10px #5b5959;
    width: 100%;
    height: 100%;
  }

  .video-container::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.3); /* 40% black overlay */
    z-index: 0;
  }

  
  .background-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
    /* opacity: 0.4; */
  }