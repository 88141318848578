.modal-content{
    padding: 20px;
    background-color: #4b4a4a !important;
    position: relative;
    /* top: 30%; */
    top: 15%;
    width: 320px !important;
    /* height: 250px; */
    height: 430px;
    display: flex;
    justify-content: center;
    margin: auto;
    border-radius: 15px !important;
}

/* for bigger screen */
@media (min-width:767px){
    .modal-content{
        width: 400px !important;
        /* height: 350px; */
        height: 450px;
        top: 20%;
    }
}


.modal-content p{
color: white;
text-align: center;
}

.discard{
    background-color: transparent;
    color: red;
    font-weight: 600;
}

.keep, .restart{
    background-color: transparent;
    color: white;
    font-weight: 600;
}
