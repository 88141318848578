.upload_button{
    background-color: rgb(255, 255, 255);
    color: black;
    font-weight: 700;
    font-size: 17px;
    border-radius: 5px;
    padding: 5px 10px;
}
/* 
.vid-upload{
    display: none;
} */

.vid-upload h2{
    text-align: center;
}

@media (min-width:768px){
    .vid-upload h2{
        /* position: absolute; */
        position: relative;
        top: 5px;
        right: 17%;
    }

}

.video-uploader-main{
    text-align: center;
    width: 100%;
    margin: 50px 0;
  padding: 50px 0;
}


.video-preview{
    width: 55%;
    height: 100vh;
    object-fit: cover;
    border: 2px solid white;
    border-radius: 30px;
}

@media (max-width : 1024px) and (min-width : 767px){
    .video-preview{
        width: 70%;
        height: 100vh;
        object-fit: cover;
        border: 2px solid white;
    border-radius: 30px;
    }
}

@media(max-width :767px){
    .video-preview{
        width: 95%;
        height: 100vh;
        border: 2px solid white;
    border-radius: 30px;
    }
}

.vid-upload{
    margin: 50px;
    padding: 50px;
    border: 1px solid gray;
    border-radius: 10px;
    box-shadow: 0px 0px 5px gray;
}

@media (max-width:768px){
    .vid-upload{
        margin: 0px;
        padding: 0px;
    }
}