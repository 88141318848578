.testimonial-container{
  /* background-color:rgb(49, 48, 48); */
  background-color: rgb(84 81 89);
  padding: 50px 0;
  /* position: relative; */
  /* z-index: -1; */
}

.testimonials-main h1{
    text-align: center;
    font-weight: 700;
    margin: 50px 0;
    color: rgb(255, 255, 255);
}

.testimonials {
    /* width: 80%; */
    margin: auto;
    position: relative;
  }
  
  .testimonial-item {
    background-color: white;
    display: flex !important;
    flex-direction: row;
    padding: 20px;
    border: 1px solid gray;
    border-radius: 5px;
    /* height: 270px; */
    height: 280px;
    width: 95% !important;
    column-gap: 20px;
    margin-bottom: 50px;
    border-radius: 10px;
  }

  @media(max-width:768px){
    .testimonial-item{
      flex-direction: column;
      height: 400px;
      margin-bottom: 50px;
    }

    .testimonial-image img{
      margin: auto;
    }
  }
  
  .testimonial-image img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    /* margin-right: 20px; */
  }
  
  .testimonial-content {
    flex: 1;
  }
  
  .testimonial-stars {
    color: gold;
    font-size: 20px;
  }

  .testimonial-stars span{
    color: orange;
    float: right;
  }
  
  .testimonial-message {
    font-size: 20px;
    color: #000000;
    margin-top: 10px;
  }

  @media(min-width:768px){
    .testimonial-message{
      font-size: 20px;
    }
  }
  
  .testimonial-user {
    font-weight: bold;
    margin-top: 10px;
  }
  
  .slick-dots{
    margin-bottom: 0px !important; 
    
  }

  .slick-dots ul li{
    color:white !important;
  }


  .slick-next{
    position: absolute;
    right: 30px;
  }

  .slick-prev{
    position: absolute;
    left: 30px;
  }

   /* Works.css */
.work-hidden {
  visibility: hidden;
  opacity: 0;
  transform: translateY(20px); /* For sliding effect */
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.work-visible {
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
}

