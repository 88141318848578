.faq-main{
    background-color: rgb(241,241,241);
    /* position: relative; */
     /* z-index: -1; */
    
}

.faq-container{
    padding: 50px 0;
}

.faq-heading h1 {
    text-align: center;
    margin-bottom: 20px;
    font-weight: 700;
}

.faq-item {
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 8px;
}

.faq-question {
    cursor: pointer;
    font-weight: bold;
    color: #000000;
    font-size: 16px;
}



.faq-question span{
    float: right;
}

.faq-answer {
    padding-top: 10px;
    color: #333;
    border-top: 1px solid #eee;
   height: 50px;
   font-size: 16px;
}

@media (max-width:768px){
    .faq-answer{
        height: 130px;
    }
}

 /* Works.css */
 .work-hidden {
    visibility: hidden;
    opacity: 0;
    transform: translateY(20px); /* For sliding effect */
    transition: opacity 0.5s ease, transform 0.5s ease;
}

.work-visible {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
}
