.sticky-btn {
    color: #fff;
    background-color: black;
    border: 1px solid rgb(255, 255, 255);
    border-radius: 5px;
    box-shadow: 0px 0px 3px rgb(75, 73, 73);
    font-size: 16px;
    position: fixed;
    bottom: 20px; 
    right: 20px; 
    padding: 10px 10px;
    display: block;
    animation: wiggle 1s ease-in-out infinite;  
    z-index: 1000;
    cursor: pointer;
}

.sticky-btn:hover{
  border: 2px solid #ffd000;
}

@keyframes bounce {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
    100% {
      transform: translateY(0);
    }
  }
  .sticky-btn {
    animation: bounce 1s ease-in-out infinite;
  } 