
  .occasion-main
  {
    padding: 50px 0;
    /* background-color: rgb(241,241,241) !important; */
    background-image: url('../images/occasion_bg.jpg');
    background-size: cover;
    
  }

 .occasion-heading h1{
    text-align: center;
    margin: 50px 0;
    font-weight: 700;
    
    }



  .carousel-container {
    width: 100%;
    margin: 20px auto;
    height: 300px ;
    overflow: hidden;
}

.occasion-item {
    border-right: 8px solid #ffd32b;
    border-bottom: 8px solid #ffd32b;
    padding: 15px;
    box-shadow: 2px 2px 4px black;
    outline: 1px solid gray;
    text-align: center;
    background: #fff;
    border-radius: 8px;
    width: 200px !important;
    margin: 20px;
    height: 280px ;
    transition: transform .5s ease-out; 
}

.occasion-content h2 {
    font-size: 18px;
    color: #000000;
    margin-top: 10px;
    font-weight: 700;
}

.occasion-content p {
    font-size: 16px;
    color: #000000;
   
}

@media(min-width:768px){
  

  .occasion-item{
    width: 200px !important;
    height: 300px !important;
  }

}

.slick-slide {
  transition: transform .5s ease;
  z-index: -2;
  transform: scale(0.7);
}

.slick-center{
  transform: scale(1);
}



.slick-dots{
 
  visibility: hidden;
}

@keyframes slideInRight{
  from{
    transform: translate(100%);
    opacity: 0;
  }
  to{
    transform: translate(0);
    opacity: 1;
  }
}

.slide-in-right{
  animation: slideInRight 1s forwards ease-out;
}

 
 .work-hidden {
  visibility: hidden;
  opacity: 0;
  transform: translateY(20px); 
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.work-visible {
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
}

.slick-arrow {
  display: none !important;
}